import React, { useEffect, useState } from "react";
import { onValue, ref } from "firebase/database";
import database from "./firebase";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css"; // Import styles

const Photos = () => {
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    const dbRef = ref(database, "/GALLERY/photos");
    onValue(dbRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const imagesArray = Object.values(data).map((photoUrl) => ({
          original: `https://showcase.greenwellsenergies.com/media/${photoUrl.blob}`,
          thumbnail: `https://showcase.greenwellsenergies.com/media/${photoUrl.blob}`,
          description: photoUrl.name || ``,
        }));
        setPhotos(imagesArray); // Convert object to array of images for the gallery
      } else {
        setPhotos([]); // Reset to empty array if no data
      }
    });
  }, []);

  return (
    <div>
      {photos.length > 0 ? (
        <ImageGallery items={photos} autoPlay={true} slideInterval={3000} />
      ) : (
        <p>No photos available</p>
      )}
    </div>
  );
};

export default Photos;
