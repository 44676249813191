// axiosConfig.js
import axios from "axios";
import NProgress from "nprogress";

// Disable the spinner
NProgress.configure({ showSpinner: false });

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: "https://suggestions.greenwellsenergies.com/api",
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    NProgress.start(); // Start the progress bar
    return config;
  },
  (error) => {
    NProgress.done(); // Complete the progress bar if there's an error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    NProgress.done(); // Complete the progress bar
    return response;
  },
  (error) => {
    NProgress.done(); // Complete the progress bar if there's an error
    return Promise.reject(error);
  }
);

export default axiosInstance;
