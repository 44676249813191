import React, { useEffect, useState } from "react";
import { onValue, ref } from "firebase/database";
import database from "./firebase";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css"; // Import styles

const Videos = () => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const dbRef = ref(database, "/GALLERY/videos");
    onValue(dbRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const videoArray = Object.values(data).map((video) => ({
          original: `https://showcase.greenwellsenergies.com/media/${video.blob}`,
          thumbnail: `https://showcase.greenwellsenergies.com/media/${
            video.thumbnail || video.blob
          }`, // Use thumbnail if available
          description: video.name || `Video ${video.blob}`,
          type: "video",
        }));
        setVideos(videoArray);
      } else {
        setVideos([]); // Reset to empty array if no data
      }
    });
  }, []);

  // Custom rendering for video items with autoplay on load
  const renderVideoItem = (item) => {
    return (
      <video
        className="image-gallery-video"
        controls
        muted
        autoPlay // Autoplay the video once it loads
        loop
        preload="auto" // Preload the video for quicker play
        onCanPlayThrough={(e) => e.target.play()} // Play when video is ready
        style={{ width: "100%", height: "auto" }}
      >
        <source src={item.original} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    );
  };

  return (
    <div>
      {videos.length > 0 ? (
        <ImageGallery
          items={videos}
          autoPlay={true} // Disable gallery autoplay, videos will handle their own autoplay
          lazyLoad={true} // Lazy load videos to optimize loading
          renderItem={renderVideoItem} // Custom rendering for videos
        />
      ) : (
        <p>No videos available</p>
      )}
    </div>
  );
};

export default Videos;
