import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter,
  Link,
  NavLink,
  Route,
  Routes,
  useParams,
} from "react-router-dom";

import Main from "./main";

import Requests from "./requests";
import Success from "./success";
import Photos from "./photos";
import Videos from "./videos";

function App() {
  const logo = process.env.PUBLIC_URL + "/logo_gwel.png";
  return (
    <BrowserRouter>
      <div className="breadcrumb-bar navbar bg-white sticky-top">
        <div className="container">
          <div className="d-flex align-items-center">
            <img src={logo} height="50" alt="Logo" />
            <h4 className="mb-0 ml-1">GREENWELLS ENERGIES</h4>
          </div>

          <div className="navbar-nav ml-auto flex-row">
            <li className="nav-item">
              <NavLink className="nav-link" href="#photos" to={"/photos"}>
                Photos
              </NavLink>
            </li>
            <li className="nav-item ml-4">
              <NavLink className="nav-link" href="#videos" to={"/videos"}>
                Videos
              </NavLink>
            </li>
          </div>
        </div>
      </div>
      <Routes>
        <Route path="/" element={<Photos />} />
        <Route path="/photos" element={<Photos />} />
        <Route path="/videos" element={<Videos />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
