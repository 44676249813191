import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCOa3wnNd0lcLz5UFhRejCyimD6sHw4DzQ",
  authDomain: "greenwells-2bc86.firebaseapp.com",
  databaseURL: "https://greenwells-2bc86-default-rtdb.firebaseio.com",
  projectId: "greenwells-2bc86",
  storageBucket: "greenwells-2bc86.appspot.com",
  messagingSenderId: "667116383495",
  appId: "1:667116383495:web:25e847bc2678fa22dfa9a2",
  measurementId: "G-6QDWL05XY4",
};
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
export default database;
